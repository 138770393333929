import { memo } from "react"
import { GridItem, Text, Button, Link, Heading } from "@chakra-ui/react"
import { CustomImage } from "@components/Image"
import { useSanityImageUrl } from "@app/hooks/useSanity"

type DownloadItemProps = {
  buttonLabel?: string
  description?: string
  directDownload?: boolean
  link?: any
  file?: any
  image?: any
  title?: string
}

const DownloadItem: React.FC<DownloadItemProps> = ({ buttonLabel, description, file, link, directDownload, image, title }: any) => {
  // uses the dl param https://www.sanity.io/docs/file-type#56c56d68424b
  const url = directDownload ? `${file?.asset?.url}?dl=` : `/${link?.handle?.current}`
  const { getSanityImageUrl } = useSanityImageUrl()

  const imgUrl = image?.asset?.url ? getSanityImageUrl(image?.asset?.url, 300, 200) : ""

  return (
    <GridItem display="flex" flexDirection="column">
      <CustomImage src={imgUrl} alt={image?.alt} w={["full"]} />
      <Heading fontSize="xl" lineHeight="24px" minHeight="48px" fontWeight={700} mt={4} mb={2}>
        {title}
      </Heading>
      <Text fontSize="md" mb={4} lineHeight="24px" flexGrow={1}>
        {description}
      </Text>
      <Link href={url}>
        <Button w={["full", "unset"]} fontSize={["xl", "md"]} px={6} py={2} h={[12, 10]}>
          {buttonLabel}
        </Button>
      </Link>
    </GridItem>
  )
}

const MemoDownloadItem = memo(DownloadItem)
export { MemoDownloadItem as DownloadItem }
