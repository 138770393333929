import { memo } from "react"
import { Listing } from "@app/components/Listing/Listing"
import { Box, Text } from "@chakra-ui/react"
import { DownloadItem } from "./DownloadItem"
import { SelectableTags } from "@components/SelectableTags"
import { useListingDownloads } from "@app/hooks/useListingDownloads"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/pages/downloads"
import type { PageProps } from "@root/types/global"

const DownloadListGridChildren = ({ filteredItems, buttonLabel }: any) =>
  filteredItems?.map(item => <DownloadItem key={item?._id} {...item} buttonLabel={buttonLabel} />)

const DownloadListing: React.FC<PageProps<Props>> = ({ page, downloads }) => {
  const { selectedTag, handleTagClick, hasItems, filteredItems } = useListingDownloads({ downloads })
  const buttonLabel = page?.additionalDownloadButtonLabel

  const headerChildren = (
    <Box mt={5}>
      <SelectableTags tags={page?.tags} selectedTag={selectedTag} handleClick={handleTagClick} />
    </Box>
  )

  const aboveGridChildren = filteredItems?.length ? (
    <Text fontSize="sm">
      Showing {filteredItems?.length} of {filteredItems?.length}
    </Text>
  ) : null

  return (
    <>
      <Listing
        headerProps={{
          title: page?.title,
          children: headerChildren,
        }}
        gridProps={{
          hasItems,
          columns: [1, 2, 3, 4],
          columnGap: [0, 4, 4],
          rowGap: [14, 14, 16],
          children: <DownloadListGridChildren filteredItems={filteredItems} buttonLabel={buttonLabel} />,
          noItemsLabel: page?.additionalNoDownloadsLabel,
        }}
        paginationProps={{
          showPagination: false,
        }}
        showSort={false}
        aboveGridChildren={aboveGridChildren}
      />
    </>
  )
}

const DownloadList: React.FC<PageProps<Props>> = ({ page, downloads }) => {
  return (
    <>
      <DownloadListing page={page} downloads={downloads} />
      {!!page?.sections?.length && <Sections page={{ content: page?.sections }} />}
    </>
  )
}

export default memo(DownloadList)
