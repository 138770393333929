import { useMemo, useCallback } from "react"
import { useListingFilters } from "@app/hooks/useListingFilters"

type useListingDownloadsArgs = {
  downloads: any
}

const useListingDownloads = ({ downloads }: useListingDownloadsArgs) => {
  const { filterItemsByTag, selectedTag, setSelectedTag } = useListingFilters()

  const downloadItems = useMemo(() => downloads?.edges?.map(({ node }: { node: any }) => node), [downloads?.edges])

  const handleTagClick = useCallback(e => setSelectedTag(e?.target?.textContent), [setSelectedTag])

  const { itemsFilteredByTag } = useMemo(
    () => filterItemsByTag({ items: downloadItems, selectedTag }),
    [downloadItems, filterItemsByTag, selectedTag]
  )

  const filteredItems = itemsFilteredByTag

  return {
    setSelectedTag,
    selectedTag,
    filteredItems,
    hasItems: !!filteredItems?.length,
    handleTagClick,
  }
}

export { useListingDownloads }
