import { graphql, PageProps } from "gatsby"
import Page from "@components/Download/DownloadList"

export type Props = PageProps<GatsbyTypes.PageDownloadsQuery, GatsbyTypes.PageDownloadsQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageDownloads {
    page: sanityPageDownloads {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 8 })
      additionalDownloadButtonLabel
      additionalNoDownloadsLabel
      tags
    }
    downloads: allSanityDownload(sort: { fields: title }) {
      edges {
        node {
          ...SanityDownloadFragment
        }
      }
    }
  }
  fragment SanityDownloadFragment on SanityDownload {
    _id
    title
    description
    image: _rawImage(resolveReferences: { maxDepth: 1 })
    file {
      asset {
        url
        originalFilename
      }
    }
    link {
      id
      handle {
        current
      }
    }
    directDownload
    tags
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
